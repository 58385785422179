import { Alert, Flex, Heading, Link, Text } from "@aws-amplify/ui-react";

export default function About() {
  return (
    <Flex direction={"column"} gap="1rem">
      <Heading level={5}>About</Heading>
      <Heading level={6}>Found a message?</Heading>
      <Text>
        Think how to rephrase it so the message is stripped of from hate,
        bullying etc. - then add fixed message as neutral.
      </Text>
      <Text>
        Short messages might be using using keywords that have ambiguous
        meaning. Context/domain is not necessary, but another message using
        similar wording while being neutral (different context/domain allowed)
        is then needed.
      </Text>
      <Heading level={6}>Understanding the classes</Heading>
      <Text>
        Let's fight pathology. There are following classes of messages:
      </Text>
      <Alert heading="Discrimination">
        Negatively biased text against a group sharing common traits (usually
        found in profile descriptions)
      </Alert>
      <Alert heading="Aggression">
        Any messages that are aggressive (for isntance - emanate
        discrimination/hate/negative bias using vulgar language)
      </Alert>
      <Alert heading="Bullying">
        Negatively biased text against a single person (usually found in direct
        messages)
      </Alert>
      <Alert heading="Drugs">Drugs related messages</Alert>
      <Alert heading="Threats">Against group or individuals.</Alert>
    </Flex>
  );
}
