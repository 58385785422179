/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessageRecord = /* GraphQL */ `
  query GetMessageRecord($id: ID!) {
    getMessageRecord(id: $id) {
      id
      ownerEmail
      ownerName
      text_hate
      text_rephrased
      discrimination
      aggression
      bullying
      drugs
      threats
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMessageRecords = /* GraphQL */ `
  query ListMessageRecords(
    $filter: ModelMessageRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerEmail
        ownerName
        text_hate
        text_rephrased
        discrimination
        aggression
        bullying
        drugs
        threats
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
