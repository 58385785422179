import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Flex,
  Heading,
  View,
  Text,
  Authenticator,
} from "@aws-amplify/ui-react";
import BlocksDecor from "./comps/BlocksDecor";
import { HashRouter, Route, Routes } from "react-router-dom";
import Layout from "./comps/Layout";
import About from "./comps/About";
import E404 from "./comps/E404";
import Collections from "./comps/Collections";

function App() {
  const comps: any = {
    _Header: () => (
      <View margin="2rem 0">
        <Flex alignItems={"center"}>
          <BlocksDecor />
          <Heading level={1} margin="1rem 0">
            Collect data
          </Heading>
        </Flex>
        <Text>Hate speech data registry.</Text>
        <Text>Collected data will help to create classification models.</Text>
        <Text>Please sign-in or register using email.</Text>
      </View>
    ),
    get Header() {
      return this._Header;
    },
    set Header(value) {
      this._Header = value;
    },
  };

  return (
    <Authenticator components={comps}>
      <HashRouter>
        <Layout>
          <Routes>
            <Route path="about" element={<About />} />
            <Route index={true} element={<Collections />} />
            <Route path="*" element={<E404 />} />
          </Routes>
        </Layout>
      </HashRouter>
    </Authenticator>
  );
}

export default App;
