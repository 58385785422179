import { API, graphqlOperation } from "aws-amplify";
import {
  GraphQLOptions,
  GraphQLResult,
  GraphQLOperation,
  OperationTypeNode,
} from "@aws-amplify/api-graphql";

import React, { useEffect, useState } from "react";
import {
  GetMessageRecordQuery,
  ListMessageRecordsQuery,
  ListMessageRecordsQueryVariables,
} from "../API";
import { getMessageRecord, listMessageRecords } from "../graphql/queries";
import { MessageRecord } from "../models";
import {
  Badge,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

type Props = {};

function Collections({}: Props) {
  const [messages, setMessages] = useState<MessageRecord[]>([]);
  useEffect(() => {
    (
      API.graphql(graphqlOperation(listMessageRecords)) as Promise<
        GraphQLResult<ListMessageRecordsQuery>
      >
    )
      .then((records) => {
        setMessages(records.data?.listMessageRecords?.items as MessageRecord[]);
        console.log(records.data?.listMessageRecords?.items as MessageRecord[]);
      })
      .catch((e) => console.log(e));
    return () => {};
  }, []);

  return (
    <Table variation="striped" highlightOnHover={true} size="small">
      <TableHead>
        <TableRow>
          <TableCell as="th">Toxic</TableCell>
          <TableCell as="th">Rephrased</TableCell>
          <TableCell as="th">Tags</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {messages.map((m: MessageRecord) => (
          <TableRow key={m.id}>
            <TableCell>{m.text_hate}</TableCell>
            <TableCell>{m.text_rephrased}</TableCell>
            <TableCell>
              {m.aggression && (
                <Badge size="small" variation="error">
                  Aggressive
                </Badge>
              )}
              {m.discrimination && (
                <Badge size="small" variation="error">
                  Discrimination
                </Badge>
              )}
              {m.bullying && (
                <Badge size="small" variation="error">
                  Bullying
                </Badge>
              )}
              {m.drugs && (
                <Badge size="small" variation="error">
                  Drugs
                </Badge>
              )}
              {m.threats && (
                <Badge size="small" variation="error">
                  Threats
                </Badge>
              )}
            </TableCell>
            {/* <p>Aggression= {m.aggression.toString()}</p>
          <p>Discrimination= {m.discrimination.toString()}</p>
          <p>Bullying= {m.bullying.toString()}</p>
          <p>Drugs= {m.drugs.toString()}</p>
          <p>Threats= {m.threats.toString()}</p> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default Collections;
