import React from "react";
import "./BlocksDecor.css";
type Props = {
  onClick?: any;
  style?: any;
};
export default function BlocksDecor({ onClick, style }: Props) {
  return (
    <div className="box" onClick={onClick} style={style}>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
      <div className="cell"></div>
    </div>
  );
}
