import {
  Flex,
  Heading,
  Text,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import BlocksDecor from "./BlocksDecor";
import NavMenu from "./NavMenu";

export default function Layout({ children }: any) {
  const auth = useAuthenticator();
  const nav = useNavigate();

  return (
    <Flex direction={"column"} gap={"1rem"} margin="auto" maxWidth="1200px">
      <Flex
        justifyContent={"space-between"}
        padding="1rem 1rem"
        position={"sticky"}
        top={"0"}
        backgroundColor={"#fff"}
        style={{ zIndex: "1000" }}
        boxShadow={{ base: "0px 2px 8px rgba(0,0,0,0.2)", medium: "none" }}
      >
        <Flex direction={"column"}>
          <Flex
            alignItems={"center"}
            onClick={() => nav("/")}
            style={{ cursor: "pointer" }}
          >
            <BlocksDecor />
            <View>
              <Heading level={3}>Collect data</Heading>
              <Text fontSize={"0.8rem"}>
                Hello {auth.user.attributes?.name}
              </Text>
            </View>
          </Flex>
        </Flex>
        <Flex alignItems={"center"}>
          <NavMenu></NavMenu>
        </Flex>
      </Flex>
      <View margin={"1rem"}>{children}</View>
    </Flex>
  );
}
