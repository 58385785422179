import { Alert, Flex, Link } from "@aws-amplify/ui-react";
import React from "react";
import { Link as RLink } from "react-router-dom";

type Props = {};

export default function E404({}: Props) {
  return (
    <Flex direction={"column"} gap="1rem">
      <Alert variation="error" heading="Error 404">
        No such page
      </Alert>
      <Link as={RLink} to={"/"} alignSelf={"flex-end"}>
        Back home
      </Link>
    </Flex>
  );
}
